export const FirebaseConfig = {
	"projectId": "taxitripapp",
	"appId": "1:121382133772:web:ac29e2baeeaac4e8754e29",
	"databaseURL": "https://taxitripapp-default-rtdb.firebaseio.com",
	"storageBucket": "taxitripapp.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAyOuzoEHJyH5jd_VjgzXHQiNUHBduf6yk",
	"authDomain": "taxitripapp.firebaseapp.com",
	"messagingSenderId": "121382133772",
	"measurementId": "G-YNZG43M1EE"
};